export default ({
    state: {
        headerData: null
    },
    mutations: {
        setHeaderData(state, data) {
            state.headerData = data
        }
    },
    actions: {
        getHeaderData({commit}, data) {
            commit('setHeaderData', data)
        }
    },
    getters: {
        headData: state => state.headerData
    },
});
