// const MEDIA_PATH_PREFIX = '/storage/app/public/media';
// const MEDIA_PATH_PREFIX = "/storage/media";
const MEDIA_PATH_PREFIX = "/media/images/original";
// const FILE_PATH_PREFIX = '/storage/app/public/files';

let utils = {
  data() {
    return {};
  },
  computed: {
    currentLang() {
      return this.$i18n.locale || "en";
    },
    currentLangForLink() {
      return this.$i18n.locale == "en" ? null : this.$i18n.locale;
    }
  },
  methods: {
    refreshSlider(refname) {
      this.$refs[refname].resize();
    },
    path(s) {
      if (process.env.NODE_ENV == "development")
        return `https://alpha.owlweb.com.ua${MEDIA_PATH_PREFIX}${s}`;
      // return `./${MEDIA_PATH_PREFIX}${s}`;
      else return MEDIA_PATH_PREFIX + s;
    },
    isExtUrl(s) {
      return s.includes("http");
    },
    onPhoneChange(e) {
      this[e.name] = e.value;
      if (e.lengthname) {
        this[e.lengthname] = e.length;
      } else {
        this.phoneLength = e.length;
      }
    },
  }
};

export let rtParams = {
  ukLocaleParam() {
    let p = {};
    for (const [key, value] of Object.entries(this.$route.params)) {
      p[key] = value;
    }
    p.locale = "uk";
    return p;
  },
  enLocaleParam() {
    let p = {};
    for (const [key, value] of Object.entries(this.$route.params)) {
      p[key] = value;
    }
    p.locale = null;
    return p;
  }
};

export { utils };
