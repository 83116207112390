<template>
  <div class="hotelItem">
    <router-link
      :to="
        propsData.link
          ? propsData.link
          : {
              name: 'HotelInfo',
              params: {
                slug: propsData.slug,
                locale: $i18n.locale == 'en' ? null : $i18n.locale
              }
            }
      "
    >
      <!-- <router-link :to="propsData.link"> -->
      <div class="imgWrp">
        <img :src="path(propsData.image)" alt="" />
      </div>
      <div class="infoBlock">
        <div class="star">{{ propsData.stars }}<i class="ic-star"></i></div>
        <p class="title">{{ propsData.name || propsData.title }}</p>
      </div>
      <div class="infoText">
        <p class="like" v-if="propsData.recommend">
          <i class="icon ic-like"></i>Рекомендуємо
        </p>
        <p class="description">
          {{ description }}
        </p>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    propsData: {
      type: [Array, Object]
    }
  },
  computed: {
    description() {
      return this.propsData.description
        ? this.propsData.description
        : this.propsData.excerpt;
    }
  }
};
</script>

<style lang="sass" scoped>
.hotelItem
    display: flex
    flex-direction: column
    background-color: $c-gray
    color: #fff
    transition: .3s
    img
        transition: 0.3s
        width: 100%
    a
        display: block
        width: 100%
        height: 100%
    .imgWrp
        max-height: 186px
        overflow: hidden
    &:hover
        background-color: $c-black
        img
            transform: scale(1.2)
    .infoText, .infoBlock
        padding: 0px 12px
    .infoBlock
        display: flex
        align-items: center
        margin: 21px 0px 17px 0px
        .star
            font-weight: 600
            font-size: 16px
            line-height: 130%
            color: #FFC107
            margin-right: 20px
            position: relative
            &::before
                content: ''
                position: absolute
                top: -5px
                left: 35px
                width: 1px
                height: 35px
                background-color: $t-gray
        .title
            font-weight: 600
            font-size: 16px
            line-height: 130%
    .infoText
        font-weight: 300
        font-size: 14px
        line-height: 100%
        padding-bottom: 22px
        i
            margin-right: 10px
            font-size: 20px
            width: 20px
            display: inline-block
        p
            display: flex
            align-items: center
            font-style: normal
            font-weight: 300
            font-size: 14px
            line-height: 140%
            color: #FFFFFF
        .like
            font-style: normal
            margin-top: 23px
            margin-bottom: 23px
            font-weight: 600
            font-size: 14px
            line-height: 100%
            color: #82CCDC
</style>
