<template>
  <div class="footerWrapper" v-if="!isUiLocked">
    <footer>
      <div class="container df jcsb fContainer">
        <div class="l">
          <component
            :is="$route.name == 'Home' ? 'span' : 'router-link'"
            class="logo"
            :to="'/'"
            >{{ globalSetting.under_logo }}</component
          >
          <p>{{$t('logoText')}}</p>
          <ul>
            <li v-for="(item, idx) in globalSetting.links" :key="idx">
              <a :href="item.link" target="_blank">
                <img :src="path(item.image)" alt="socLogo" class="socialIcon" />
              </a>
            </li>
          </ul>
        </div>
        <div class="r">
          <ul v-if="globalSetting.footerMenu">
            <li
              v-for="(item, idx) in globalSetting.footerMenu.items"
              :key="idx"
            >
              <!-- <router-link :to="item.url">{{ item.name }}</router-link> -->
               <app-link class="appLink" :link="item"></app-link>
            </li>
          </ul>
          <p class="ceoText">
            {{ globalSetting.footer_text }}
          </p>
        </div>
      </div>
      <span class="hr"></span>
    </footer>
    <div class="bottomFooter">
      <div class="container df jcsb">
        <ul class="df jcc payment">
          <li>
            <a href="#">
                <img :src="require('@/assets/image/visa.svg')" alt="visa-logo">
            </a>
          </li>
          <li>
            <a href="#">
                <img :src="require('@/assets/image/mastercard-logo.svg')" alt="mastercard-logo">
            </a>
          </li>
        </ul>
        <ul class="df jcsb">
          <li>{{ globalSetting.copy }}</li>
          <li>{{ globalSetting.developer_text }}</li>
        </ul>
        <p>{{ globalSetting.license }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["globalSetting", "isUiLocked"])
  }
};
</script>

<style lang="sass" scoped>
footer
  background-color: darken(#17171D, 1)
  color: #fff
  padding-top: 44px
  @media (max-width: 576px)
      padding-top: 34px
  .l
    width: 25%
    @media (max-width: 576px)
      width: 100%
      text-align: center
    p
      font-weight: 600
      font-size: 16px
      line-height: 130%
      color: #FFFFFF
    ul
      display: flex
      justify-content: center
      li
        &:hover
            i
                color: $c-btn
        &:first-of-type
          margin-right: 22px
  .r
    width: 70%
    @media (max-width: 576px)
      display: none
    ul
      display: flex
      li
        a
          font-weight: 600
          font-size: 15px
          line-height: 100%
          color: #FFFFFF
        &:not(:last-of-type)
          margin-right: 20px
    .ceoText
      font-weight: 300
      font-size: 17px
      line-height: 150%
      color: #CACACA
  .logo
    color: #6DBFD1
    font-size: 45px
    font-weight: 500
.hr
  display: block
  margin-top: 44px
  border-bottom: 1px solid #777777
  @media (max-width: 576px)
      margin-top: 32px
.bottomFooter
  padding: 14px 0px
  background-color: darken(#17171D, 1)
  color: #777777
  @media (max-width: 768px)
      .payment
          display: none
  @media (max-width: 576px)
      .container
        display: flex
        flex-wrap: wrap
        flex-direction: column-reverse
        ul:first-of-type
            display: none
        ul
            justify-content: space-between
        p
            color: #FFFFFF
            margin-bottom: 20px
            font-size: 12px

  ul, p
    margin: 0px
  ul
    display: flex
    justify-content: center
    li:not(:last-of-type)
      margin-right: 7px
.fContainer
    @media (max-width: 1024px)
        flex-direction: column
        align-items: center
        .r, .l
            width: 100%
    .l
        text-align: center
.socialIcon
    @media (max-width: 768px)
        width: 39px
    @media (max-width: 576px)
        width: 29px
</style>
