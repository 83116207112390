<template>
    <div>
        <app-tours v-if="propsData.template === 'grid'" :propData="propsData"></app-tours>
        <appChooseUs v-if="propsData.template === 'custom'" :propData="propsData"></appChooseUs>
        <app-show-case v-if="propsData.template === 'list'" :propData="propsData"></app-show-case>
    </div>
</template>

<script>
import appChooseUs from "../app-chooseUs.vue";
import AppShowCase from '../app-showCase.vue';
import AppTours from '../app-tours.vue';
export default {
  components: { appChooseUs, AppTours, AppShowCase },
  props: {
    propsData: {
      type: [Object, Array],
    },
  },
};
</script>

<style lang="sass" scoped>
</style>
