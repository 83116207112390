<template>
    <li class="accordion__item">
        <div
            class="accordion__trigger"
            :class="{ accordion__trigger_active: visible }"
            @click="open"
        >
            <!-- This slot will handle the title/header of the accordion and is the part you click on -->
            <slot name="accordion-trigger"></slot>
        </div>

        <transition
            name="accordion"
            @enter="start"
            @after-enter="end"
            @before-leave="start"
            @after-leave="end"
        >
            <div class="accordion__content" v-show="visible">
                <ul>
                    <!-- This slot will handle all the content that is passed to the accordion -->
                    <slot name="accordion-content"></slot>
                </ul>
            </div>
        </transition>
    </li>
</template>

<script>
export default {
    props: {},
    inject: ["Accordion"],
    data() {
        return {
            index: null,
        };
    },
    computed: {
        visible() {
            return this.index == this.Accordion.active;
        },
    },
    methods: {
        open() {
            if (this.visible) {
                this.Accordion.active = null;
            } else {
                this.Accordion.active = this.index;
            }
        },
        start(el) {
            el.style.height = el.scrollHeight + "px";
        },
        end(el) {
            el.style.height = "";
        },
    },
    created() {
        this.index = this.Accordion.count++;
    },
};
</script>

<style lang="sass" scoped>
.accordion__item > div
  cursor: pointer
  position: relative
  i::before
    color: #c3c2c2
.accordion__trigger
  display: flex
  justify-content: space-between

.accordion-enter-active,
.accordion-leave-active
  will-change: height, opacity
  transition: height 0.3s ease, opacity 0.3s ease
  overflow: hidden

.accordion-enter,
.accordion-leave-to
  height: 0 !important
  opacity: 0

.accordion__trigger_active
    color: #fff
.filterTour
    .accordion__content ul
        display: grid
        grid-template-columns: 1fr
        font-weight: 500
        font-size: 15px
        line-height: 125%
        color: #555555
        text-decoration-line: underline
        grid-row-gap: 10px
</style>
