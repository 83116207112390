<template>
  <header v-if="getSize === 'desktop' && !isUiLocked">
    <div class="header__top container df jcsb">
      <a
        v-if="globalSetting.phones"
        :href="`tel:+${globalSetting.phones[0].number}`"
        class="telephone"
        >{{ globalSetting.phones[0].label }}</a
      >
      <div class="r-block df ait jcsb">
        <app-lang-switcher></app-lang-switcher>
      </div>
    </div>
    <div class="header__bot">
      <div class="container no-p df jcsb">
        <component
          :is="$route.name == 'Home' ? 'span' : 'router-link'"
          class="logo"
          :to="'/'"
          >{{ globalSetting.logo_text }}</component
        >
        <nav-menu :propData="headData"></nav-menu>
      </div>
    </div>
  </header>
  <!-- todo  -->
  <header v-else>
    <div class="container header__bot df jcsb">
      <component
        :is="$route.name == 'Home' ? 'span' : 'router-link'"
        class="logo"
        :to="'/'"
        >{{ globalSetting.logo_text }}</component
      >
      <button class="burgerTrigger" @click="menuOpen = !menuOpen">
        <span></span>
        <span></span>
        <span></span>
      </button>
    </div>
    <!-- todo -->
    <div class="mobMenu" v-if="menuOpen">
      <div class="top">
        <a
          v-if="globalSetting.phones && getSize !== 'mobile'"
          :href="`tel:+${globalSetting.phones[0].number}`"
          class="telephone"
          >{{ globalSetting.phones[0].label }}</a
        >
        <div class="r-block df ait jcsb">
          <app-lang-switcher></app-lang-switcher>
        </div>
        <button class="burgerTriggerClose" @click="menuOpen = !menuOpen">
          &times;
        </button>
      </div>
      <div class="bot">
        <component
        :is="getSize === 'mobile' ? 'nav-menu-m' : 'nav-menu'"
        :propData="headData"
        ></component>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AppLangSwitcher from "./app-langSwitcher.vue";
import NavMenuM from './nav_menu/navMenu-m.vue';
import NavMenu from "./nav_menu/navMenu.vue";
export default {
  data() {
    return {
      menuOpen: false
    };
  },
  components: {
    AppLangSwitcher,
    NavMenu,
    NavMenuM
  },
  watch: {
        $route() {
            this.menuOpen = false
        }
    },
  computed: {
    ...mapGetters(["getSize", "isUiLocked", "headData", "globalSetting"])
  },
  methods: {
    ...mapActions(["lockUi", "unlockUi", "getHeaderData", "getFooterMenu"])
  },
  async created() {
    this.lockUi();
    const res = await this.axios.post("/api/menu/get-by-ids", {
      ids: [1, 2],
      lang: this.currentLang
    });
    this.getHeaderData(res.data.data.items[1]);
    this.getFooterMenu(res.data.data.items[2]);
    this.unlockUi();
  }
};
</script>

<style lang="sass" scoped>
header
    background-color: $c-black
    color: #fff
.header__top
    padding: 10px 0px
.header__bot
    background-color: $c-gray
    position: sticky
    top: 0px
    z-index: 1001
.logo
    font-size: 34px
    font-weight: 600
    padding: 8.5px 0px
ul
    margin: 0px
.telephone
    font-weight: 300
    font-size: 17px
    line-height: 150%
    text-align: center
    color: $t-gray
    margin-left: 20px
.burgerTrigger
    border: none
    background-color: unset
    display: flex
    flex-direction: column
    justify-content: center
    cursor: pointer
    span
        background-color: $c-btn
        width: 36px
        height: 3px
        &:not(:last-of-type)
            margin-bottom: 6px
// mobMenu style
.mobMenu
    position: fixed
    top: 0px
    background-color: $c-gray
    width: 100%
    z-index: 1000
    padding: 20px
    @media (max-width: 576px)
        min-height: 100vh
    .top
        margin-bottom: 77px
        display: flex
        justify-content: space-between
    .bot
        display: flex
        justify-content: center
        @media (max-width: 576px)
            width: 100%
            justify-content: flex-start
    .burgerTriggerClose
        font-size: 60px
        line-height: 30px
        border: unset
        background-color: unset
        color: #2A4651
        transition: .3s
        &:hover
            color: $c-btn
</style>
