<template>
  <section
    class="info container"
    :class="{ reverse: propsData.image_position == 'right' }"
  >
    <div class="blockWrapper">
      <img :src="path(propsData.image)" alt="" />
      <div class="infoWrapper">
        <p class="title">{{ propsData.title }}</p>
        <p class="descr" v-html="propsData.description"></p>
        <router-link
          class="btn"
          v-if="propsData.button_link"
          :to="propsData.button_link"
          >{{ propsData.button_title }}</router-link
        >
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    propsData: {
      type: [Object, Array],
    },
  },
};
</script>

<style lang="sass" scoped>
// info
.info
    margin: $section-offset auto
    text-align: right
    @media (max-width: 768px)
        margin: 100px auto
    img
        max-width: 1071px
        margin-left: auto
        @media (max-width: 1071px)
            max-width: 784px
        @media (max-width: 784px)
            max-width: 516px
            margin-bottom: -5px
        @media (max-width: 576px)
            max-width: 100%
    .infoWrapper
        position: absolute
        background-color: $c-gray
        bottom: -50px
        width: 520px
        padding: 35px
        left: 0px
        text-align: left
        @media (max-width: 576px)
            bottom: -130px !important
            position: static
            width: unset
        .title
            font-weight: 500
            font-size: 25px
            line-height: 140%
            color: #fff
            margin-bottom: 25px
            @media (max-width: 576px)
                font-size: 16px
        .descr
            font-weight: 300
            font-size: 17px
            line-height: 150%
            color: $t-gray
            @media (max-width: 576px)
                font-size: 14px !important
        @media (max-width: 768px)
            padding: 25px
            .title
                margin-bottom: 20px
                font-weight: 500
                font-size: 16px !important
                line-height: 140%
            .descr
                font-weight: 300
                font-size: 17px
                line-height: 150%
        @media (max-width: 576px)
            text-align: center
    &.reverse
        text-align: left
        .infoWrapper
            right: 0px
            left: unset
        img
            margin-left: unset
.blockWrapper
    position: relative
</style>
