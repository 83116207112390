<template>
  <section class="calendar">
    <h2 class="sectionTitle bef bef-fix">Календар відпочинку по місяцям</h2>
    <div class="container">
      <ul class="filter">
        <li
          v-for="(item, idx) in propsData.months"
          :class="{ active: currentIdx == idx }"
          :key="idx"
          @click="getIdx(idx)"
        >
            {{ item }}
        </li>
      </ul>
    </div>
    <div class="darkBg">
      <div class="container">
        <ul class="filterTour">
            <app-data-grid-item
             v-for="(item, idx) in propsData.items[currentIdx]"
            :key="idx"
            :item="item"
            >
            </app-data-grid-item>

        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import appDataGridItem from './app-dataGrid-item.vue';
export default {
  components: { appDataGridItem },
  props: {
    propsData: {
      type: [Array, Object],
    },
  },
  data() {
    return {
      currentIdx: 1,
    };
  },
  methods: {
    getIdx(idx) {
      this.currentIdx = +idx;
    },
  },
};
</script>

<style lang="sass" scoped>
// calendar
.calendar
    display: flex
    flex-direction: column
    align-items: center
    @include sectionPadding
    padding-bottom: 0px
    .filter
        display: flex
        color: #fff
        @media (max-width: 1024px)
            overflow-x: scroll
            width: 100%
        li
            cursor: pointer
            padding: 8px 16px
            transition: .3s
            &:hover
                background-color: #2A4651
            &:not(:last-of-type)
                position: relative
                &::after
                    content: ''
                    width: 1px
                    height: 60%
                    position: absolute
                    background-color: #28292F
                    right: 0px
                    top: 50%
                    transform: translateY(-50%)
.filterTour
    display: grid
    width: 100%
    margin-top: 35px
    grid-template-columns: repeat( 4, 1fr)
    grid-gap: 8px
    padding-bottom: $section-offset
    @media (max-width: 1024px)
        grid-template-columns: repeat(3, 1fr)
    @media (max-width: 768px)
        grid-template-columns: repeat(2, 1fr)
        padding-bottom: 100px
    @media (max-width: 576px)
        grid-template-columns: 1fr
    li
        width: 100%
.filterTitle
    font-size: 12px
    line-height: 100%
    color: $t-gray
.type
    display: flex
    border: 2px solid #4AA7BC
    box-sizing: border-box
    margin-top: 10px
    margin-bottom: 35px
    li
        padding: 10px 14px
        font-weight: 300
        font-size: 14px
        line-height: 100%
        color: #4AA7BC
        &.active
            color: $c-black
            font-weight: 600
            background-color: #4AA7BC
.tour
    height: 210px
    position: relative
    z-index: 2
    background-size: cover
    a
        display: block
        width: 100%
        height: 100%
        padding: 15px
        display: flex
        // align-items: flex-end
        justify-content: flex-end
        flex-direction: column
    &::after
        content: ''
        position: absolute
        z-index: -1
        top: 0px
        left: 0px
        width: 100%
        height: 100%
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%)
    .title
        font-weight: 700
        font-size: 19px
        line-height: 110%
        color: #FFFFFF
    .price
        font-weight: 300
        font-size: 17px
        line-height: 150%
        color: #FFFFFF
.active
    color: $c-btn
    background-color: $c-black
.darkBg
    width: 100%
</style>
