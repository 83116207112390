<template>
  <router-link v-if="data.url" :to="data.url" class="newItem">
    <p class="tag">{{ data.name }}</p>
    <p class="title">
      {{ data.name }}
    </p>
    <p class="descr" v-html="data.text"></p>
    <p class="date">{{data.public_date}}</p>
  </router-link>
</template>

<script>
export default {
  name: "AppNewsItem",
  props: {
    data: {
      type: Object
    }
  }
};
</script>

<style lang="sass" scoped>
.newItem
    padding: 20px
    background-color: #28292F
    .tag
        font-weight: 600
        font-size: 12px
        line-height: 130%
        color: #4AA7BC
        position: relative
        margin-bottom: 20px
        &::before
            content: '|'
            color: #4AA7BC
            margin-right: 10px
    .title
        font-size: 16px
        line-height: 130%
        color: #FFFFFF
        font-weight: 600
        margin-bottom: 10px
    .descr
        font-weight: 300
        font-size: 17px
        line-height: 150%
        color: #CACACA
        min-height: 110px
        margin-bottom: 25px
        max-height: 105px
        overflow-y: hidden
    .date
        font-size: 12px
        line-height: 100%
        color: #CACACA
</style>
