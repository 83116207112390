<template>
  <section class="darkBg">
    <div class="news container">
      <h2 class="sectionTitle bef">Новини</h2>
      <div class="newsWrapper">
        <app-news-item v-for="(item, idx) in propsData.list" :data="item"  :key="idx"></app-news-item>
      </div>
      <div class="bottomInfo">
        <div class="text">
          <p class="number">{{ propsData.count_articles }}+</p>
          <div class="descr">
            {{ propsData.subtitle }}
          </div>
        </div>
        <component
          v-if="propsData.btn_link && propsData.btn_name"
          class="btn"
          :is="isExtUrl(propsData.btn_link) ? 'default-link' : 'router-link'"
          :target="isExtUrl(propsData.btn_link) ? '_blank' : '_self'"
          :to="propsData.btn_link"
        >
          {{ propsData.btn_name }}
        </component>
      </div>
    </div>
  </section>
</template>

<script>
import appNewsItem from "./common/app-news-item.vue";
import CheckBoxGroup from "./common/inputs/CheckBoxGroup.vue";
export default {
  components: { appNewsItem, CheckBoxGroup },
  props: {
    propsData: {
      type: [Array, Object]
    }
  }
};
</script>

<style lang="sass" scoped>
// news
.news
    @include sectionPadding
    display: flex
    justify-content: center
    flex-direction: column
    align-items: center
    .newsWrapper
        margin-top: 77px
        display: grid
        width: 100%
        grid-template-columns: repeat(3, 1fr)
        grid-gap: 30px
        margin-bottom: 80px
        @media (max-width: 768px)
            grid-template-columns: repeat(2, 1fr)
            margin-top: 53px
        @media (max-width: 576px)
            grid-template-columns: repeat(1,1fr)
        .newItem
            transform: unset !important
</style>
